
.brews-page{
  width: 100%;
  height: 100%;
  background-color: #373a47;

  .thumbnails {
    width: 1600px;
    max-width: 100%;

    .thumbnail {
      margin: 10px;
      width: 300px;
      height: 300px;
      cursor: pointer;
    }
  }
}
