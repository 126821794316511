
.brew {
  background-color: #373a47;
}

.label {
  $padding: 10px;
  width: calc(100% - #{$padding} * 2);
  max-width: 700px;
  margin: $padding;
}