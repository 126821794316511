
.PatientKnight {
  $brew-content-text-margin-width: 50px;

  background: #282c34;

  .brew-content {
    $font-color: #EEEEEE;
    $font-size-base: 14px;
    $font-size-scaling: 1vmin;
    $line-height: 1.5;
    position: relative;
    margin: auto;
    background-color: #363636;
    display: block;
    box-sizing: border-box;
    width: 800px;
    max-width: 100%;
    font-size: calc(#{$font-size-base} + #{$font-size-scaling});
    color: $font-color;
    text-align: left;
    line-height: $line-height;

    font-family: Tahoma, Geneva, sans-serif;

    h1, h2, h3, h4 {
      font-family: Georgia, "Palatino Linotype", Palatino, serif;
    }

    ol {
      list-style-type: none;
      padding: 0;
    }

    hr {
      margin: 30px 0;
      border-color: $font-color;
      opacity: 0.8;
    }

    .full-width-block {
      width: 100%;
      display: block;
    }

    .label-top-background {
      position: absolute;
    }

    .label-top-left-spacer {
      float: left;
      width: calc(53vw - #{$brew-content-text-margin-width});
      height: 50vw;
      margin: 0 10px 10px 0;
    }

    .label-bottom-spacer {
      visibility: hidden;
    }

    .label-bottom {
      position: absolute;
      bottom: 0;
    }

    .blobs {
      $horizontal-margin: -40px;
      width: calc(100% - #{$horizontal-margin} * 2);
      margin: 50px $horizontal-margin 60px;
    }

    .illuminated-letter {
      $num-lines: 3;
      $total-scale: $line-height * $num-lines;
      width: calc(#{$font-size-base * $total-scale} + #{$font-size-scaling * $total-scale});
      border: 1px $font-color solid;
      margin: 0 5px;
      float: left;
    }

    .brew-content-text {
      margin: 10px $brew-content-text-margin-width;
      position: relative;
      z-index: 2;
    }
  }

  @media screen and (min-width: 800px) {
    padding: calc(50vw - 400px);

    .brew-content {
      .label-top-left-spacer {
        width: 424px - $brew-content-text-margin-width;
        height: 400px;
      }
    }
  }

  @media screen and (min-width: 880px) {
    padding: 40px 0;
  }
}