.App {
  text-align: center;
}

.image-importer-drop-zone {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff88;
}

.linked-image-container,
.linked-image {
  position: relative;
  touch-action: none;

  .highlighted-link {
    position: absolute;
    background-color: #FFFF0044;
  }

  .highlighted-path {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: -1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullpage {
  padding: 100px 0;
  margin: auto;
}

body {
  height: 100%;
  min-height: 1px;
  margin: 0;
  padding: 0;
  background-color: #373a47;
}

.content {
  margin: auto;
  background-color: #282c34;
  display: block;
  box-sizing: border-box;
  width: 800px;
  max-width: 100%;
  padding: 50px;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  img {
    max-width: 100%;
    width: 700px;

  }
}

.App-header {
  display: block;
  width: 800px;
  padding: 50px;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-weight: bolder;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
