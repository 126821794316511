
.topnav {
  background-color: #373a47;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  button, a {
    background-color: #373a47;
    border: none;
    cursor: pointer;

    flex: 0 1 200px;
    height: 40px;
    margin: 5px;

    font: 20px Tahoma, Geneva, sans-serif;
    color: #DDDDDD;

    &:not(:first-child) {
      border-left: 1px white solid;
    }
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
