.speech-node {

  .speech-history {
    height: 500px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;

    .speech-history-line {
      width: 100%;
      display: flex;

      &.response {
        justify-content: end;
        align-content: center;
        .speech-history-text {
          float: right;
        }
      }

      .speech-history-text-container {
        flex: 0 0 80%;
        margin: 10px 20px;
      }

      .speech-history-text {
        padding: 10px 20px;
        margin: 0;
        max-width: 100%;
        position: relative;
        float: left;

        font: 16px Tahoma, Geneva, sans-serif;
        color: #DDDDDD;
        background-color: #373a47;
        text-align: left;
      }
    }
  }

  .speech-option {
    background-color: #373a47;
    border: none;
    cursor: pointer;
    width: 100%;

    text-align: left;
    flex: 0 1 200px;
    margin: 5px;
    padding: 10px 30px;

    font: 16px Tahoma, Geneva, sans-serif;
    color: #DDDDDD;
  }
}
