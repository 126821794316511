
.GreenApprentice {
  background: #282c34;

  @media screen and (min-width: 800px) {
    padding: calc(50vw - 400px);
  }

  @media screen and (min-width: 880px) {
    padding: 40px 0;
  }

  .brew-content {
    $font-color: #222222;
    $font-size-base: 14px;
    $font-size-scaling: 1vmin;
    $line-height: 1.5;
    position: relative;
    margin: auto;
    background-color: #8DD7D7;
    display: block;
    box-sizing: border-box;
    width: 800px;
    max-width: 100%;
    font-size: calc(#{$font-size-base} + #{$font-size-scaling});
    color: $font-color;
    text-align: left;
    line-height: $line-height;

    font-family: Tahoma, Geneva, sans-serif;

    h1, h2, h3, h4 {
      font-family: Georgia, "Palatino Linotype", Palatino, serif;
    }

    ol {
      list-style-type: none;
      padding: 0;
    }

    img {
      width: 100%;
      display: block;
    }

    hr {
      margin: 30px 0;
      border-color: $font-color;
      opacity: 0.8;
    }

    .label-top-background {
      position: absolute;
    }

    .label-bottom-spacer {
      visibility: hidden;
    }

    .label-bottom {
      position: absolute;
      bottom: 0;
    }

    .blobs {
      $horizontal-margin: -40px;
      width: calc(100% - #{$horizontal-margin} * 2);
      margin: 50px $horizontal-margin 60px;
    }

    .illuminated-letter {
      $num-lines: 3;
      $total-scale: $line-height * $num-lines;
      width: calc(#{$font-size-base * $total-scale} + #{$font-size-scaling * $total-scale});
      border: 1px $font-color solid;
      margin: 0 5px;
      float: left;
    }

    .brew-content-text {
      margin: 10px 50px;
      position: relative;
      z-index: 2;
    }
  }
}