
@mixin rhombus-layout($tile-size) {
  // margin-scale-factor must be kept in sync with MARGIN_SCALE_FACTOR in ./Gallery.jsx
  $margin-scale-factor: 30;
  $margin: $tile-size / $margin-scale-factor;
  @media screen and (min-width: $tile-size*1.5 + $margin*2 + 40px) {
    padding-top: $tile-size / 4;
    padding-bottom: $tile-size / 4;

    .rhombus {
      // mask replaced with svg clip path, see ImageTile
      //mask-image: url(./images/rhombus.svg);
      //mask-size: cover;

      width: $tile-size;

      margin: #{-$tile-size / 4 + $margin / 2} $margin;

      &.left-spaced {
        margin-left: $tile-size / 2 + $margin*2;
      }

      &.right-spaced {
        margin-right: $tile-size / 2 + $margin*2;
      }

      &:hover, &:focus-visible {
        mask-image: none;
        position: relative;
        z-index: 2;
      }
    }

    .spacer {
      opacity: 0;
    }
  }
}

.gallery {
  width: 100%;
  height: 100%;
  background-color: #373a47;

  .main-image {
    width: 1200px;
    margin: 20px auto;
  }

  .thumbnails {
    width: 1800px;
    margin: auto;
    max-width: 100%;

    line-height: 0;

    @include rhombus-layout(200px);
    @include rhombus-layout(300px);
    @include rhombus-layout(500px);
  }

  .rhombus {
    $margin: 10px;
    width: calc(100% - #{$margin*2});
    margin: $margin;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

}
